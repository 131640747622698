<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Projet personnel</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2022</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Développement front-end/back-end, webdesign
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Au cours de mon année de césure, j'ai commencé à apprendre la langue
						coréenne en autodidacte car j'ai toujours souhaité apprendre une
						nouvelle langue étrangère qui ne soit pas une langue occidentale
						afin de découvrir de nouvelles cultures, étendre mon esprit et
						apporter un regard différent sur certaines choses.<br /><br />
						Une part importante de l'apprentissage d'une langue passe par le
						vocabulaire car il faut ré-apprendre tous les mots que nous
						connaissons déjà. Souhaitant noter de temps à autre des mots que
						j'aurais appris lors de mes leçons ou autrement pour y revenir plus
						tard, j'ai eu l'idée de créer ce projet afin de me faciliter la
						tâche. Celui-ci consiste en un gestionnaire de données assez
						classique utilisant les méthodes de base de la persistance de
						données (CRUD) mais adapté afin de respectivement :<br />
						- pouvoir ajouter, modifier ou supprimer du vocabulaire<br />
						- afficher la liste de vocabulaire en utilisant la pagination<br />
						- pouvoir filtrer la liste de vocabulaire (barre de recherche, trier
						par date de publication, trier par catégorie de mot)<br />
						- pouvoir afficher les derniers mots de vocabulaire ajoutés sous la
						forme d'un carousel<br />
						- pouvoir afficher un mot aléatoire provenant de la base de données
						<br /><br />
						Ainsi, j'ai pour cela utilisé le framework Laravel combiné à une
						base de données MySQL simple pour ce projet. En effet, le modèle de
						la base de données ne contient qu'une table "words" contenant
						l'identifiant du mot, le mot en anglais, en français, en coréen, sa
						catégorie (nom, verbe, adjectif etc.) ainsi que sa date de création
						et de modification. Pour la partie back-end, j'ai créé les
						différentes méthodes dans un controller nommé "WordController" afin
						de communiquer avec la base de données. J'ai ensuite réalisé la
						partie front-end en utilisant le framework Bootstrap pour obtenir un
						résultat qui me convient.
					</p>

					<!--Image 1-->

					<img
						src="../assets/vocabularylist/page_accueil.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la page d'accueil avec le carousel et la section pour générer un mot aléatoire provenant de la base de données"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la page d'accueil avec le carousel et la section pour
						générer un mot aléatoire provenant de la base de données
					</p>

					<!--Image 2-->

					<img
						src="../assets/vocabularylist/page_vocabulary_list.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la page listant tous le vocabulaire avec un système de pagination et de filtres"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la page listant tout le vocabulaire avec un système de
						pagination et de filtres
					</p>

					<!--Image 3-->

					<img
						src="../assets/vocabularylist/modal_add_new_word.jpg"
						class="img-fluid col-sm-12 col-md-12 col-lg-12 col-xl-12"
						alt="Aperçu de la modal permettant d'ajouter un nouveau mot de vocabulaire"
					/>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Aperçu de la modal permettant d'ajouter un nouveau mot de
						vocabulaire
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Vocabulary List",
			meta: [
				{
					name: "description",
					content:
						"Découvrez le projet de création d'un gestionnaire de vocabulaire personnel réalisé par Antonin Winterstein !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "VOCABULARY LIST - SITE WEB",
				urlImage: require("../assets/projets/fond_vocabulary_list.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
